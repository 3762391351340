import Home from './Home';
import NotFound from './NotFound';
import Event from './Event';
import SubmitEvent from './SubmitEvent';

export default {
  Home,
  NotFound,
  Event,
  SubmitEvent,
};
